import { filter, map, pipe, toPairs } from "ramda"
import { useEffect } from "react"
import { useRecoilValue } from "recoil"
import { httpDomain } from "./lib/utils"
import * as st8 from "./state"
const IC = require('ic-js')

export default function SearchResults ({ tags }) {
  const knownIcServers = useRecoilValue(st8.knownIcServersState)
  const goodServers = pipe(
    toPairs,
    filter(([k, v]) => v.status === 'ok'),
    map(([k, v]) => k)
  )(knownIcServers)
  useEffect(() => {
    console.log('tags', tags)
    console.log('knownIcServers', goodServers)
    const go = async () => {
      goodServers.forEach(async server => {
        const ic = IC.create()
        await ic.import(httpDomain(server) + `/index.ic?seed=${encodeURIComponent(tags.join('\n'))}&depth=1`)
        if (ic) {
          console.log(server)
          console.log(ic.all())
        }
      })
    }
    go()

  }, [JSON.stringify(tags), JSON.stringify(goodServers)])
  return (
    <div className='search-results'>
      hi
    </div>
  )
}
