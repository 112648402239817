import { useEffect } from "react"
import { fromPairs, reduce, groupBy, mapObjIndexed, values, sort, filter, prop, map, join, concat, find, last, propEq, split, pipe, curry, ifElse, identity, always, toPairs } from 'ramda'
import { ethers } from 'ethers'
import { get, set } from 'idb-keyval'
import IC from 'ic-js'

export const PUBLIC_IC_SERVERS = ['yes.aye.si', 'do.aye.si']

export const icName = pipe(
  split('/'),
  last
)
export const getDbId = thing => {
  if (!thing) return false
  if (IC.isIcUrl(thing)) {
    return thing.replace(/^https?:\/\//, '')
  }
  return icName(thing)
}

export const getDbSettings = curry((icSettings, dbId) => {
  let allSettings = [icSettings]
  if (allSettings.links) {
    allSettings = concat(allSettings, allSettings.links)
  }
  return find(propEq('id', dbId), allSettings) || {}
})

export const getDomain = url => {
  const match = url.match(/https?:\/\/[^\/]+\/?/)
  return match ? match[0] : ''
} 

export const cleanDomain = url => {
  return url.replace(/^https?:\/\//, '').replace(/\/$/, '')
}
export const httpDomain = url => {
  url = cleanDomain(url)
  return `${url.startsWith('localhost')? 'http' : 'https'}://${url}` 
  // return `${window.location.protocol}//${url.replace(/^https?:\/\//, '').replace(/\/$/, '')}` 
}

export const fetchIcfsInfo = async (server) => {
  const ic = new IC({ importDepth: 1 })
  const url = httpDomain(server)
  await ic.import(url + `/index.ic`)
  if (ic.all().length === 0) return {}
  const ret = {
    name: server,
    url
  }
  const name = ic.findTagged(['icfs', 'name'])
  if(name[0]) {
    ret.name = name[0]
  }
  const desc = ic.findTagged(['icfs', 'description'])
  if (desc[0]) {
    ret.description = desc[0]
  }
  return ret
}

export const setIdb = async (key, val) => {
  try {
    await set(key, JSON.stringify(val))
  } catch (err) {
  }
}

export const getIdb = async key => {
  try {
    const val = await get(key)
    if (val) {
      return JSON.parse(val)
    }
  } catch (err) {
  }
}

export const setStorage = (key, val) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(val))
  } catch (err) {

  }
}

export const getStorage = key => {
  try {
    const val = window.localStorage.getItem(key)
    if (val) {
      return JSON.parse(val)
    }
  } catch (err) {
  }
}

export const icUrl = icAddress => {
  return `${window.location.protocol}//${window.location.host}${window.location.pathname}?ic=${icAddress}`
}

export const seePathForTags = tags => {
  return `/c/${tags.map(encodeURIComponent).sort((a, b) => a.localeCompare(b)).join('/')}`
}

export const getUrlQuery = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const query = {}
  urlParams.forEach((v, k) => {
    query[k] = v
  })
  return query
}

export const pin = async (content, fileName) => {
  const jwt = getStorage('pinataJwt')
  if (!jwt) return
  const formData = new FormData()
  if (typeof content === 'string') {
    formData.append('file', new Blob([content], { type: "text/ic"}), fileName)
  } else if (content instanceof Blob) {
    formData.append('file', content)
  }
  if (fileName) {
    formData.append('pinataMetadata', JSON.stringify({
      name: fileName
    }))
    formData.append('pinataOptions', JSON.stringify({
      wrapWithDirectory: true,
      cidVersion: 1
    }))
  }
  return fetch('https://api.pinata.cloud/pinning/pinFileToIPFS', {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwt}`
    },
    body: formData
  })
    .then(res => res.json())
    .then(res => {
      console.log(res)
      if (fileName) {
        return res.IpfsHash + '/' + fileName
      }
      return prop('IpfsHash', res)
    })
    .catch(err => {
      console.log(err)
    })
}

export const mostActiveSort = sort((a, b) => b.count - a.count)

export const FILTERS = [{
  label: '> 0',
  func: filter(t => t.sum > 0)
}, {
  label: 'All',
  func: identity
}, {
  label: 'Unacknowledged',
  func: filter(t => !t.me)
}, {
  label: 'Acknowledged',
  func: filter(prop('me'))
}]
export const SORTS = [{
  label: 'Most Active',
  func: mostActiveSort
}, {
  label: 'Highest Rated',
  func: pipe(
    sort((a, b) => b.sum - a.sum)
  )
}, {
  label: 'Most Controversial',
  func: sort((a, b) => {
    // this shoud be a percent
    const diff = Math.abs(a.sum) - Math.abs(b.sum)
    if (diff !== 0) return diff
    return b.count - a.count
  })
}]

export const what = what => {
  console.log(what)
  return what
}

export const isSomething = v => v || v === 0

export const copyToClipboard = async str => {
  await navigator.clipboard.writeText(str)
}

export const countGroupedTags = pipe(
  mapObjIndexed((uniqTags, tag) => {
    return {
      count: uniqTags.length,
      sum: uniqTags.reduce((acc, val) => val.yesNo === '-' ? acc - 1 : acc + 1, 0),
      yes: uniqTags.filter(t => t.yesNo !== '-').length,
      no: uniqTags.filter(t => t.yesNo === '-').length,
      tag
    }
  }),
  values
)

export const cleanAndCountTags = pipe(
  groupBy(prop('from')),
  countGroupedTags
)

export const countAllEdges = curry((allTags, depth, tag) => {
  if (depth === 0) return 0
  const thisTagsChildren = pipe(
    getChildren(tag),
    toPairs,
    filter(([k, v]) => k !== tag),
    fromPairs,
    countGroupedTags,
    filter(t => t.sum > 0)
  )(allTags)
  return thisTagsChildren.reduce((acc, val) => acc + val.count, 0) + map(countAllEdges(allTags, depth - 1), thisTagsChildren).reduce((acc, val) => acc + val, 0)
})

// tag is false if you want all
export const getParents = tag => {
  return pipe(
    filter(t => isSomething(tag) ? t.from === tag : true),
    groupBy(prop('to'))
  )
}

// tag is false if you want all
export const getChildren = tag => {
  return pipe(
    filter(t => isSomething(tag) ? t.to === tag : true),
    groupBy(prop('from'))
  )
}

export const isIcUrl = IC.isIcUrl

export const isImage = tag => {
  return tag.endsWith('.jpg') || tag.endsWith('.png') || tag.endsWith('.gif')
}
export const isVideo = tag => {
  return tag.endsWith('.mp4') || tag.endsWith('.webm') || tag.endsWith('.mov') || tag.endsWith('.m4v')
}
export const srcForTag = tag => {
  if (tag.startsWith('/ipfs/')) {
    return 'https://gateway.pinata.cloud' + tag
  }
  return tag
}
export const fetchSignedNonce = async (signer, server, user) => {
  const nonce = await fetch(server + `/${user}/_nonce`)
    .then(res => res.text())
    .catch(err => console.log(err))
  return await signer.signMessage(nonce)
}
const _icUpdate = async (method, signer, server, user, icStr) => {
  const headers = {
    'Content-Type': 'text/ic',
  }
  // jwt
  if (typeof signer === 'string') {
    headers['Authorization'] = 'Bearer ' + signer
  // key signer
  } else {
    const signed = await fetchSignedNonce(signer, server, user) 
    headers['x-ic-nonce'] = signed
  }
  return fetch(httpDomain(server) + `/${user}`, {
    method,
    body: icStr,
    headers
  })
  .then(res => res.json())
  .catch(err => {
    if (/Unauthorized/.test(err)) {
      alert(`Unauthorized. Your change didn\'t save. Make sure you have permission to access ${server}`)
    }
    return({ error: err })
  })
}
export const patchIc = curry(async (signer, server, user, icStr) => {
  return _icUpdate('PATCH', signer, server, user, icStr)
})
export const saveIc = curry(async (signer, server, user, icStr) => {
  return _icUpdate('POST', signer, server, user, icStr)
})

export const patchIcWithPersp = curry(async (perspective, icStr) => {
})

export const fetchJwt = async (signer, server, user, data) => {
  const signed = await fetchSignedNonce(signer, server, user) 
  let send = {
    method: 'POST',
    body: signed
  }
  if (data) {
    send = {
      ...send,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data, nonce: signed })
    }
  }
  return fetch(server + `/${user}/_jwt`, send)
  .then(res => res.text())
  .catch(err => false)
}

export const getSigner = async (perspectives, icFileServerUser) => {
  const persp = perspectives.find(p => p.address === icFileServerUser)
  let signer 
  if (persp && persp.private) {
    signer = new ethers.Wallet(persp.private)
  } else if (window.ethereum) {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    await provider.send("eth_requestAccounts", []);
    signer = provider.getSigner()
    const addr = await signer.getAddress()
    if (addr !== icFileServerUser) {
      signer = null
    }
  }
  return signer
}

export const createPerspective = () => {
  const wallet = ethers.Wallet.createRandom()
  const persp = {
    public: wallet.publicKey, 
    private: wallet.privateKey, 
    address: wallet.address
  }
  console.log('we\'re creating a private key for you:', persp)
  return persp
}

export const compressAddr = (addr, start = 4, end = 2) => {
  return addr.slice(0, start) + '...' + addr.slice(addr.length - end)
}

export const labelForUser = (icFileServerUser, perspectives) => {
  const persp = perspectives.find(p => p.address === icFileServerUser)
  if (persp && persp.label) return persp.label
  return compressAddr(icFileServerUser) 
}

export const useDebouncedEffect = (effect, deps, delay) => {
  useEffect(() => {
      const handler = setTimeout(() => effect(), delay);

      return () => clearTimeout(handler);
  }, [...(deps || []), delay]);
}

  // format date string
export const formatDate = (d) => {
  if (!d) return ''
  const date = new Date(parseInt(d, 10))
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  return `${hours}:${minutes}:${seconds} ${month}/${day}/${year}`
}